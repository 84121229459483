import {promptStream, setStream} from './components/ute/setStream.js';
import {watchPriceBreaks} from './components/prices/watchPriceBreaks.js';
import {hoverZoom} from './components/products/hoverZoom.js';
import {ProductCarousel} from './components/carousels/ProductCarousel.js';
import {ImagePickerCarousel} from './components/carousels/ImagePickerCarousel.js';
import {setupPlpImageCarousels} from './components/carousels/PlpImageCarousel.js';
import * as qv from './components/products/quickView.js';
import {insertMerchRows} from './components/merch/insertMerchRows.js';
import { show_added_assembly } from './components/products/showAddedAssembly.js';
import { dom_has_loaded } from './components/ute/onPageLoad.js';
import {getGwPrice} from './components/prices/gwPrice.js';
import {viewAlternatives} from './components/products/alternatives.js';
import {SaveCartReminder} from './components/addToCart/SaveCartReminder.js';
import {searchToggle} from './components/search/searchToggle';
import { refine_open, refine_close, filterRefinements, searchFilterRefinements } from './components/search/refinement';
import { openMobileAccountMenu } from './components/universalMenu/openMobileAccountMenu';
import { hideToastrz }  from './components/products/toastrz';
import { previouslyOrdered } from './components/products/previouslyOrdered';
import { fireDatalayerEvent } from './components/ute/datalayer.js';
import nxDrawer from './components/drawer/drawer.js';
import { cancelDialog } from './components/backorders/backorders.js';
import { getPasswordStrength } from './components/pwdMeter/pwdMeter.js';

window.nx ??= {};
window.nx.ute ??= {};
window.nx.prices ??= {};
window.nx.products ??= {};
window.nx.carousels ??= {};
window.nx.merch ??= {};
window.nx.addToCart ??= {};
window.nx.search ??= {};
window.nx.drawer ??= {};
window.nx.universalMenu ??= {};
window.nx.backorders ??= {};
window.nx.pwdMeter ??= {};

// setstream utility for ab testing
window.nx.ute.setStream = setStream;
window.nx.ute.promptStream = promptStream;
window.nx.ute.dom_has_loaded = dom_has_loaded;
window.nx.ute.fireDatalayerEvent = fireDatalayerEvent;

// watchPriceBreaks to adjust prices when quantity changes for eligible products
window.nx.prices.watchPriceBreaks = watchPriceBreaks;

// hoverZoom for zooming images on hover on the pdp and quickview
window.nx.products.show_added_assembly = show_added_assembly;
window.nx.products.hoverZoom = hoverZoom;

// carousels
window.nx.carousels.ImagePickerCarousel = ImagePickerCarousel;
window.nx.carousels.ProductCarousel = ProductCarousel;
window.nx.carousels.setupPlpImageCarousels = setupPlpImageCarousels;

// drawer
window.nx.drawer.nxDrawer = nxDrawer;

// quickview
window.nx.products.maybeQuickView = qv.maybeQuickView;
window.nx.products.quickView = qv.quickView;

// previously ordered items
window.nx.products.previouslyOrdered = previouslyOrdered;

// toastrz
window.nx.products.hideToastrz = hideToastrz;

// insert merch rows every nth row
window.nx.merch.insertMerchRows = insertMerchRows;

// click for price
window.nx.prices.getGwPrice = getGwPrice;

// discontinued and deranged
window.nx.products.viewAlternatives = viewAlternatives;

// save cart reminder
window.nx.addToCart.SaveCartReminder = SaveCartReminder;

// backorders
window.nx.backorders.cancelDialog = cancelDialog;

// search
window.nx.search.searchToggle = searchToggle;
window.nx.search.refine_open = refine_open;
window.nx.search.refine_close = refine_close;
window.nx.search.filterRefinements = filterRefinements;
window.nx.search.searchFilterRefinements = searchFilterRefinements;

// password strength
window.nx.pwdMeter.getPasswordStrength = getPasswordStrength;

// openMobileAccountMenu
window.nx.universalMenu.openMobileAccountMenu = openMobileAccountMenu;

// fire functions specified in the datalayer
if (window.nxDatalayer && window.nxDatalayer.event) {
    addEventListener('DOMContentLoaded', () => {
        Object.keys(nxDatalayer.event).forEach(ev => {
            fireDatalayerEvent(nxDatalayer.event[ev]);
        });
    });
}
