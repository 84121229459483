export function getWidthAndHeight(el) {
    if(!el?.cloneNode) {
        return null;
    }
// be careful with this function.
// it may return an incorrect response if the content contains an image 
// that hasn't been loaded, and doesn't have a size specified
    const clone = el.cloneNode(true);
    Object.assign(clone.style, {
        overflow: 'visible',
        height: 'auto',
        maxHeight: 'none',
        opacity: '0',
        visibility: 'hidden',
        display: 'block',
        width: '100vw'
    });

    const holder = document.createElement('div');
    holder.style.display = 'flex';
    holder.insertBefore(clone, null);
    document.body.insertBefore(holder, null);

    const height = clone.offsetHeight;
    const width = clone.offsetWidth;

    holder.remove();

    return {
        height: height,
        width: width
    };
}
