import {
  hasClassName
} from '../ute/utils';

const openMobileAccountMenu = () => {
  setTimeout(function () {
    document.querySelectorAll('.menu_header_icon.u-menu-button')[0].click();
    var icon = document.querySelectorAll('.main-menu-dropdown.menu-open .u-menu-expand')[0];
    if (hasClassName(icon, 'fa-plus')) { icon.click(); }
  }, 0);
}

export { openMobileAccountMenu };
