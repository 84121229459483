/* globals nx */
import { addNotificationEvent } from '../notification/stock';

function viewAlternatives(va_button, prodcode, prod, layout, bundable, card_size) {
    let successFunc = popupAlternatives;
    let dlg;
    layout = 'grid';
    Dialogs.load();
    dlg = new Dialog({
      title: 'Alternatives to:',
      topClassName:"alternatives-dlg-top",
      className:"alternatives-dlg",
      content: ''
    });
    dlg.open();

    const item_layout = 'grid';

    const btn = va_button;

    const url = `/main-ajax-alternatives?prodcode=${prodcode}&bundable=${bundable}&layout=${item_layout}&card_size=${card_size}`;
    fetch(url, {
        credentials: 'same-origin'})
        .then(response => {
            if (!response.ok) {
                throw Error('bad response');
            }
            return response.text();
        })
        .then(alts_html => {
            // If the ajax service finds zero alternatives, mainly due to access control
            // reasons, it will return the view similar url. Redirect user when this happens
            const vs_url_re = /^\/main-catalogue-similar/;
            if( vs_url_re.test(alts_html) ) {
                window.location = alts_html;
            }
            // Viewable alternatives found
            else {
              successFunc(alts_html, btn, prodcode, prod,
                                    layout, bundable, card_size, dlg);
            }
        })
        .catch(e => {
            btn.remove();
            new Dialog({title: 'No alternatives found',
                        content: 'Sorry, there are no alternatives for this product',
                        autoOpen: true});
        });
}

function popupAlternatives(alts_html, va_button, prodcode, prod, layout, bundable, card_size, dlg) {
    // update dialog contents
    if(dlg) {
        const extraTitle = document.createElement('span');
        extraTitle.setAttribute('class', 'normal');
        extraTitle.textContent = ` ${prod}`;
        document.getElementById('dialog-title').appendChild(extraTitle);
        dlg.opt.content = alts_html;
        dlg.setContent();
        addNotificationEvent(prodcode);
    }

    if( etdp ) {
        // etdp.getAllTargets('.alt_' + prodcode);
        etdp.getAllTargets('#dialog-content');
        etdp.updateAllTargets();
    }
    if(nx.prices.PriceLoader){
        nx.prices.PriceLoader.initializeElements('#dialog-content');
    }
    if(window.listLoader){
        // window.listLoader.initializeHearts('.alt_' + prodcode);
        window.listLoader.initializeHearts('#dialog-content');
    }
    // AJAX add to cart
    new nx.addToCart.AddToCartForm('#dialog-content'); // eslint-disable-line no-use-before-define, no-undef

}

export {viewAlternatives};
