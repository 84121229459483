import { setCookie, getCookie } from './cookie';

const setStream = (p, val) => {
    let cook = getCookie('stvi');
    cook = cook.slice(0, p) + val + cook.slice(p + 1, 32);
    setCookie('stvi', cook);
    document.location = document.location;
};
const promptStream = () => {
    const streams = {
        a:{p:0,s:2,r:0},
        b:{p:0,s:2,r:1},
        c:{p:1,s:2,r:0},
        d:{p:1,s:2,r:1},
        w:{p:2,s:4,r:0},
        x:{p:2,s:4,r:1},
        y:{p:2,s:4,r:2},
        z:{p:2,s:4,r:3},
        s1:{p:3,s:8,r:0},
        s2:{p:3,s:8,r:1},
        s3:{p:3,s:8,r:2},
        s4:{p:3,s:8,r:3},
        s5:{p:3,s:8,r:4},
        s6:{p:3,s:8,r:5},
        s7:{p:3,s:8,r:6},
        s8:{p:3,s:8,r:7}
    };
    const cook = getCookie('stvi');
    let html = `stvi: ${cook}<br/>`;
    for (const stream in streams) {
        const s = streams[stream].s;
        const r = streams[stream].r;
        const p = streams[stream].p;
        const actual = cook.substr(p, 1);
        if(parseInt(actual,16) % s == r){
            html += `>&nbsp; ${stream}<br/>`;
        }else{
            html += `&nbsp; &nbsp;<a href="javascript:nxDatalayer.setStream(${p}, ${r})">${stream}</a><br/>`;
        }
    }
    html += `<a href="javascript:Dialogs.close()">Cancel</a>`;
    Dialogs.load();
    new Dialog({title: 'Pick a stream',content: html}).open();
};

export { promptStream, setStream };
