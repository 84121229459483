/* global $j, nx */
import { doAdobeEvent } from "../ute/utils";

function hoverZoom(elem, hover_zoom_target) {
    let hoverZoomPanel,hoverZoomCursor,imgSrc,
        bgWidth, bgHeight, bgRatio, longSide,
        zoomBoxSide, fullImg, pdpImgWidth,
        bgTopOffset = 0,
        bgLeftOffset = 0,
        recorded = false;
    let hover_zoom_target_id = hover_zoom_target || 'hover_zoom_target';
    function centered(num){
        num = num - (zoomBoxSide / 2) + 10;
        if(num < 0){return 0}
        if(num > (pdpImgWidth - zoomBoxSide)){return (pdpImgWidth - zoomBoxSide)}
        return num;
    }
    elem.zoom_enter = function(evt){
        hoverZoomPanel = document.createElement('div');
        hoverZoomPanel.className = 'hoverZoomPanel';
        hoverZoomPanel.style.backgroundImage = 'url(' + imgSrc +')';
        if(! document.getElementById('description_container')) {
            document.getElementById(hover_zoom_target_id).insertBefore(hoverZoomPanel, null);
        }
        hoverZoomCursor = document.createElement('div');
        hoverZoomCursor.className = 'hoverZoomCursor';
        hoverZoomCursor.style.width = zoomBoxSide + 'px';
        hoverZoomCursor.style.height = zoomBoxSide + 'px';
        elem.parentNode.insertBefore(hoverZoomCursor, null);
        if(!recorded){
            doAdobeEvent('event96', 'PDP hover zoom');
            recorded = true;
        }
    };
    elem.zoom_move = function(evt){
        var pTop = centered(evt.offsetY);
        var pLeft = centered(evt.offsetX);
        if(hoverZoomPanel){
            hoverZoomPanel.style.backgroundPosition = (-1*((pLeft * bgRatio) - bgLeftOffset)) +'px ' + (-1* ((pTop * bgRatio) - bgTopOffset)) + 'px';
        }
        if(hoverZoomCursor){
            hoverZoomCursor.style.top = pTop + 'px';
            hoverZoomCursor.style.left = pLeft + 'px'
        }
    };
    elem.zoom_leave = function(){
        if(hoverZoomCursor){hoverZoomCursor.remove();}
        if(hoverZoomPanel){hoverZoomPanel.remove();}
    };


    elem.zoom_init = function(){
        elem.removeEventListener('mouseenter', elem.zoom_enter);
        elem.removeEventListener('mousemove', elem.zoom_move);
        elem.removeEventListener('mouseleave', elem.zoom_leave);
        imgSrc = elem.src.replace('full', 'lgsq');
        fullImg = document.createElement('img');
        fullImg.src = imgSrc;
        fullImg.addEventListener('load',function(evt){
            pdpImgWidth = elem.clientWidth;
            bgWidth = evt.target.width;
            bgHeight = evt.target.height;
            longSide = bgWidth > bgHeight ? bgWidth : bgHeight;
            zoomBoxSide = pdpImgWidth * 420 / longSide;
            bgRatio = longSide / pdpImgWidth;
            if(bgWidth > bgHeight) {
                bgTopOffset = ((bgWidth - bgHeight) / 2);
                bgLeftOffset = 0;
            }else if(bgHeight > bgWidth){
                bgLeftOffset = ((bgHeight - bgWidth) / 2);
                bgTopOffset = 0;
            }
            elem.addEventListener('mouseenter', elem.zoom_enter);
            elem.addEventListener('mousemove', elem.zoom_move);
            elem.addEventListener('mouseleave', elem.zoom_leave);
        });
    };

    return elem.zoom_init();
}

export {hoverZoom};
