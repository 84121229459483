/* globals nx, s, toastr */
import { Dialog } from "../dialog/dialog-no-prototype"; // eslint-disable-line
import { removeClassName, addClassName } from '../ute/utils';

export function cancelDialog(args) {
  const {prodcode, img, description, found, bo_msg, linenumber, quantity, orderid, reason, can_token, token, ccode} = args;

  let descr = description.replace(/[^a-zA-Z0-9]/g, '-');
  if (!descr) {
    descr = 'generic';
  }

  Dialogs.load();
  Dialogs.close();

  const showSuccess = () => {
    const msg = `
      <div class="inline_message inline_message-success">
        <div class="inline_message__icon inline_message__icon-success"></div>
        <div class="inline_message__content">
          <div style="display:flex;justify-content: space-between">
            <div>
              <p><b>Your item has been cancelled</b></p>
              <p>
                Your original item <b>${description}</b> has been removed from order
                <b>${orderid}</b>. We’re sorry that your item did not arrive in time.
              </p>
            </div>
            <div style="margin:auto 0 auto 16px">
              <a href="/main-orders-backorders" class="wc-button wc-button--secondary">
                <span class="label">Return&nbsp;to&nbsp;backorders</span>
              </a>
            </div>
          </div>
        </div>
      </div>`;

    const msg_box = document.createElement('div');
    msg_box.innerHTML = msg;

    return msg_box;
  };

  const showFailed = () => {
    const msg = `
      <div class="inline_message inline_message-error">
        <div class="inline_message__icon inline_message__icon-error"></div>
        <div class="inline_message__content">
          <div style="display:flex;justify-content: space-between">
            <div>
              <p><b>Your item has NOT been cancelled</b></p>
              <p>
                Sorry, your original item <b>${description}</b> from the order
                <b>${orderid}</b> could not be cancelled at this moment.
                Please try again later.
              </p>
            </div>
            <div style="margin:auto 0 auto 16px">
              <a href="/main-orders-backorders" class="wc-button wc-button--secondary">
                <span class="label">Return&nbsp;to&nbsp;backorders</span>
              </a>
            </div>
          </div>
        </div>
      </div>`;

    const msg_box = document.createElement('div');
    msg_box.innerHTML = msg;

    return msg_box;
  };

  // function to cancel item
  const cancelItem = () => {
    nx.dlg_cancel.opt.content = '<div style="text-align: center;"><p><b>Cancelling ' + prodcode + '</b></p>' +
                                '<p><img src="/media/images/new/ajax-loader.gif" /></p></div>'; // eslint-disable-line
    nx.dlg_cancel.setContent();
    // fetch to the api
    const uri = `/main-ajax-cancelitem`;
    let params = [];

    params.push(`linenumber=${linenumber}`);
    params.push(`prodcode=${prodcode}`);
    params.push(`orderid=${orderid}`);
    params.push(`reason=${reason}`);
    params.push(`can_token=${can_token}`);

    const paramString = `?${params.join('&')}`;

    fetch(uri + paramString, {
        credentials: 'same-origin',
        headers: {
          'X-Post-ID': nxDatalayer.global.post_id
        },
        method: "POST"
    })
    .then(response => {
        if (!response.ok) {
            if ((/^4/).test(response.status)) {
                toastr['error']('There has been a error. ' +
                    'Please sign out and then sign in again before trying to cancel an item',
                    'System Error');
                throw new Error('System Error',{ cause: 'System Error' });
            } else {
                throw new Error(response.text());
            }
        }

        return response.text();
    })
    .then(txt => {
        const data = JSON.parse(txt);
        const ob = document.getElementById('original_backorder');

        if (ob) { // Find Similar page
            document.getElementById('btto').click(); // scroll Back To TOp

            ob.querySelector('H1').className = 'hidden';
            ob.querySelector('.orderrow').className = 'hidden';

            let ims = document.querySelectorAll('.inline_message');
            ims.forEach(im => {
                im.remove();
            });
            
            const good_msg = showSuccess();
            ob.appendChild(good_msg);
        } else {
            window.nx.backorders.tried_cancel({ prodcode: prodcode,
              bo_msg: bo_msg,
              description: description,
              descr: descr,
              found: found,
              linenumber: linenumber,
              orderid: orderid,
              qty: quantity,
              reason: reason,
              token: token
              }, '');
        }

        if (window.nx.backorders.updateAccount) { // Trigger reload backorders
            window.nx.backorders.updateAccount({ccode: ccode});
        }

        // do the adobe event
        s.events = 'event153';
        s.linkTrackEvents = 'event153';
        s.linkTrackVars = 'pagename,events,products';
        s.products = `;${prodcode}`;
        s.tl(true, 'o', 'Backorder cancel complete');

        // close the dialog
        Dialogs.close();
    })
    .catch(error => {
        if (error.cause !== 'System Error') {
            const ob = document.getElementById('original_backorder');

            if (ob) { // Find Similar page
                document.getElementById('btto').click(); // scroll Back To TOp

                ob.querySelector('H1').className = 'hidden';
                ob.querySelector('.orderrow').className = 'hidden';

                let ims = document.querySelectorAll('.inline_message');
                ims.forEach(im => {
                    im.remove();
                });

                const bad_msg = showFailed();
                ob.appendChild(bad_msg);
            } else { // Back orders page
                window.nx.backorders.tried_cancel({ prodcode: prodcode,
                  bo_msg: bo_msg,
                  description: description,
                  descr: descr,
                  found: found,
                  linenumber: linenumber,
                  orderid: orderid,
                  qty: quantity,
                  reason: reason,
                  token: token
                  }, 'error');
            }
        }

        Dialogs.close();
    });
  }

  // do an adobe event
  s.events = 'event154';
  s.linkTrackEvents = 'event154';
  s.linkTrackVars = 'pagename,events';
  s.tl(true, 'o', 'Backorder cancel popup');

  // show the dialog
  const title = 'Cancel item';
  const button_back = document.createElement('button');
  button_back.type = 'button';
  button_back.className = 'wc-button wc-button--link';
  button_back.innerHTML = '<span class="label">Go back</span>';
  button_back.addEventListener('click', () => {
    Dialogs.close();
  });

  const button_cancel = document.createElement('button');
  button_cancel.type = 'button';
  button_cancel.className = 'wc-button wc-button--primary';
  button_cancel.innerHTML = '<span class="label">Cancel item</span>';
  button_cancel.addEventListener('click', cancelItem);

  const button_bar = document.createElement('div');
  button_bar.className = 'cancel-backorder__buttons';
  button_bar.appendChild(button_back);
  button_bar.appendChild(button_cancel);

  const blurb = `
    <div class="cancel-backorder">
      <p>
        The following item will be cancelled from order <strong>${orderid}</strong>.
        You will not receive this item. You will not be charged for this item if it is not yet paid.
        If it has been paid, you will receive a refund for this
        item and any associated fees and charges.
      </p>
      <div class="cancel-backorder__box">
        <div class="cancel-backorder__data">
          <img src="${img}" alt="${description}" class="cancel-backorder__img" />
          <div class="cancel-backorder__details">
            <p>${description}</p>
            <div class="cancel-backorder__data">
              <div class="cancel-backorder__item">
                <label class="cancel-backorder__label">Quantity</label><br />
                ${quantity}
              </div>
              <div class="cancel-backorder__item">
                <div >
                  <label class="cancel-backorder__label">Product code</label><br />
                  ${prodcode}
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>`;

  const content = document.createElement('div');
  content.innerHTML = blurb;

  const cbb = content.querySelector('.cancel-backorder__box');
  cbb.insertAdjacentElement('afterend', button_bar);

  nx.dlg_cancel = new Dialog({
    title,
    className: 'cancel-backorder',
    topClassName: 'cancel-backorder-dialog-top',
    padding: 0,
    margin: 0,
    width: '607',
    innerScrollOnlyY: true,
    content
  });
  Dialogs.elm('close').innerText = ''; // Remove X
  Dialogs.elm('close').className = 'fa fa-close';

  nx.dlg_cancel.open();

}
