/* globals nx */
import {readDatalayer} from '../ute/utils'

function maybeQuickView(prodcode) {
    //if we don't have a postcode, prompt for one first
    if(!readDatalayer(['global', 'postcode'])) {
        return nx.addToCart.pc_lightbox(null, prodcode);
    }
    return quickView(prodcode);
}
// quick view
function quickView(prodcode) {
    let addButton, gridItemForm, gridItemFields, newForm,
    griditem, quickview, left, right, hover_zoom_target,
    backlink, breadcrumb, image, promo, title, codes,
    swatch, stock, price, atc, etd, icons, ksp, view, 
    link, divInfo, kspec,
    feature, feature_ident, featureParam = '';
    function yoink(toElem, fromSelector){
        // grabs something from the card to display in the quickview
        const myDiv = griditem.querySelector(fromSelector);
        if(myDiv) {
            // Remvoe full width for a new button in quickview
            var button = myDiv.querySelector('button');
            if(button) {
                button.classList.remove('wc-button--fullwidth');
                button.classList.remove('wc-button--small');
            }
            toElem.innerHTML = myDiv.innerHTML;
        }
    }
    function addForm(dinfo){
        let qtyField, span, div, increaser, decreaser, from_blowup, linenote, linenoteLabel,
        addToList, newListButton, newAddButton;
        addButton = griditem.querySelector('.griditem_atc input[value=Add]') || griditem.querySelector('.griditem_atc_wc button[value="Add"]') ;
        if(addButton){
            let form_prefix = '';
            gridItemForm = addButton.form;
            newForm = document.createElement('form');
            newForm.className = 'quickview_form';
            // hidden fields
            newForm.name = gridItemForm.name;
            newForm.method = gridItemForm.method;
            newForm.enctype = gridItemForm.enctype;
            gridItemFields = gridItemForm.querySelectorAll('input');

            for(var i = 0; i < gridItemFields.length; i++) {
                if( gridItemFields[i].name == "k2c_additeminline_prodcode" ) {
                    form_prefix = 'k2c_additeminline_';
                }
                if( gridItemFields[i].type == 'hidden' ){
                    newForm.appendChild(gridItemFields[i].cloneNode());
                }
            }
            from_blowup = document.createElement('input');
            from_blowup.type = 'hidden';
            from_blowup.name = form_prefix + 'from_blowup';
            from_blowup.value = 'quickview';
            newForm.appendChild(from_blowup);

            // linenote
            linenote = dinfo.querySelector('.quickview_linenote');
            if(linenote) {
                linenote = linenote.cloneNode(true);
                linenoteLabel = document.createElement('label');
                linenoteLabel.className = 'quickview_linenote-label';
                linenoteLabel.title =  "Add item notes for your own use. Notes will be printed on your invoice.";
                linenoteLabel.innerHTML = 'Add note <i id="linenotehelp" class="fa fa-question-circle tooltip" aria-hidden="true" ></i>';
                linenote.insertAdjacentElement('afterbegin', linenoteLabel);
                newForm.appendChild(linenote);
                // we can't have a line note and also quickview_kspec as it takes up too much room
                kspec.style.display = 'none';
            }

            // qty field
            qtyField = gridItemForm.querySelector('input.qty_text');
            if(qtyField){
                div  =document.createElement('div');
                span = document.createElement('span');
                span.className = 'quickview_qtywrap';
                increaser = document.createElement('button');
                increaser.className = 'quickview_increaser increaser';
                increaser.title = 'Increase quantity';
                increaser.addEventListener('click', nx.products.increase_qty);
                increaser.type="button";
                decreaser = document.createElement('button');
                decreaser.title = 'Decrease quantity';
                decreaser.className = 'quickview_decreaser decreaser';
                decreaser.addEventListener('click', nx.products.decrease_qty);
                decreaser.type="button";
                span.appendChild(decreaser);
                span.appendChild(qtyField.cloneNode());
                span.appendChild(increaser);
                div.appendChild(span);
                let pbs = document.createElement('span');
                pbs.classList.add('quickview_pricebreaks');
                div.appendChild(pbs);
                newForm.appendChild(div);
            }
            
            // atc button
            newAddButton = addButton.cloneNode();
            if(newAddButton.tagName == 'INPUT') {
                newAddButton.value = 'Add to Cart';
                newForm.appendChild(newAddButton);

                // add to list
                addToList = griditem.querySelector('.griditem_add-to-list');
                if(addToList){
                    newListButton = addToList.cloneNode(true);
                }
                newListButton.classList.add('quickview_add-to-list');
                newListButton.classList.remove('griditem_add-to-list');
                newForm.appendChild(newListButton);
            } else {
                // New button
                newAddButton.innerHTML = '<span class="label">Add to Cart</span>';
                newAddButton.classList.remove('wc-button--small');
                newAddButton.classList.remove('wc-button--fullwidth');
                div = document.createElement('div');
                div.className = 'quickview_atc_button';
                div.appendChild(newAddButton);

                // add to list
                addToList = griditem.querySelector('.griditem_add-to-list');
                if(addToList.children.length > 0) {
                    newListButton = addToList.cloneNode(true);
                    newListButton.classList.add('quickview_add-to-list');
                    newListButton.classList.remove('griditem_add-to-list');
                    // remove class from button iniside newListButton   
                    newListButton.querySelector('button').classList.remove('wc-button--small');
                    newListButton.querySelector('button').classList.remove('wc-button--tertiary');
                    newListButton.querySelector('button').classList.add('wc-button--secondary');
                    newListButton.querySelector('button').classList.add('wc-button--medium__icon');
                    div.appendChild(newListButton);
                }

                newForm.appendChild(div);
            }

            atc.appendChild(newForm);
        } else {
            yoink(atc, '.griditem_atc');
        }

    }
    function buildImage(dinfo) {
        function setThumbClick(thumbImg, bigImg) {
            return e => {
                bigImg.src=thumbImg.replace('thumb.png','lgsq.jpg');
                bigImg.zoom_init();
                for (var n=e.target.parentNode.firstChild ; n; n = n.nextSibling ){
                    n.classList.remove('selected');
                }
                e.target.classList.add('selected');
            };
        }
        const dimg = dinfo.getAttribute('data-image');
        const dthumb = dinfo.getAttribute('data-thumb').split(',');
        let ret = '';
        let img, thumbs, thumb, middle, inner;
        if(dimg == "noimg") {
            let newImage = document.createElement('div');
            newImage.className = 'quickview_image';
            return newImage;
        }

        ret = document.createElement('div');
        ret.className = 'quickview_main-image';
        img = document.createElement('img');
        img.src = dimg;
        img.setAttribute('draggable', false);
        img.className = 'quickview_main-image';
        nx.products.hoverZoom(img, 'hover_zoom_target_qv');
        ret.appendChild(img);
        if(dthumb.length > 1) {
            // multi images
            thumbs = document.createElement('div');
            thumbs.id = 'quickview_thumbs';
            thumbs.className = 'quickview_thumbs';
            middle = document.createElement('div');
            middle.className = 'middle';
            inner = document.createElement('div');
            inner.className = 'inner';
            thumbs.appendChild(middle);
            middle.appendChild(inner);
            for(var i = 1; i < dthumb.length; i++){
                thumb = document.createElement('img');
                thumb.src = dthumb[i];
                thumb.className = 'display_box';
                thumb.setAttribute('draggable', false);
                thumb.addEventListener('click',setThumbClick(dthumb[i], img));
                inner.appendChild(thumb);
            }
            ret.appendChild(thumbs);
        }
        return ret;
    }

    griditem = document.querySelector('.griditem[data-prodcode="' + prodcode + '"]:not(.griditem--bundable)');
    divInfo = griditem.querySelector('.griditem_quickview_info');
    quickview = document.createElement('div');
    quickview.className = 'quickview';
    left = document.createElement('div');
    left.className = 'quickview_left';
    right = document.createElement('div');
    right.className = 'quickview_right';
    hover_zoom_target = document.createElement('div');
    hover_zoom_target.id = 'hover_zoom_target_qv';
    hover_zoom_target.className = 'hover_zoom_target';
    backlink = document.createElement('div');
    backlink.innerHTML = '<span class="quickview_backlink_icon">Back to previous page</span>';
    backlink.addEventListener('click', function(){Dialogs.close();});
    breadcrumb = document.createElement('div');
    breadcrumb.className = 'quickview_breadcrumb';
    image = document.createElement('div');
    image.className = 'quickview_image';
    promo = document.createElement('div');
    promo.className = 'quickview_promo';
    title = document.createElement('div');
    title.className = 'quickview_title';
    codes = document.createElement('div');
    codes.className = 'quickview_codes';
    swatch = document.createElement('div');
    swatch.className = 'quickview_swatch';
    stock = document.createElement('div');
    stock.className = 'quickview_stock';
    price = document.createElement('div');
    price.className = 'quickview_price';
    atc = document.createElement('div');
    atc.className = 'quickview_atc';
    etd = document.createElement('div');
    etd.className = 'quickview_etd';
    icons = document.createElement('div');
    icons.className = 'quickview_icons';
    ksp = document.createElement('div');
    ksp.className = 'quickview_ksp';
    kspec = document.createElement('div');
    kspec.className = 'quickview_kspec';
    view = document.createElement('div');
    view.className = 'quickview_view';

    codes.innerHTML = 'Product Code: <span class="select-me">' + prodcode + '</span>';
    yoink(title, '.griditem_title');
    view.innerHTML = title.innerHTML;
    link = view.querySelector('a');
    link.innerHTML = '<span class="label">View Full Product Details<i class="fa-solid fa-angle-right fa-lg"></i></span>';
    link.classList.add('wc-button');
    link.classList.add('wc-button--secondary');
    link.classList.add('wc-button--big');
    link.classList.add('wc-button--fullwidth');
    yoink(kspec, '.quickview_kspec');
    yoink(promo, '.griditem_promo');
    yoink(swatch, '.griditem_swatch');
    if(swatch.innerHTML != '') {
        swatch.innerHTML = '<label class="quickview_label">Available in:</label>' + swatch.innerHTML;
    }
    yoink(price, '.griditem_price');
    addForm(divInfo);
    etd.innerHTML = '<div class="delivery-info delivery-info-noLink" data-prodcode="' + prodcode + '">&nbsp;</div>'

    quickview.appendChild(left);
    quickview.appendChild(right);
    left.appendChild(backlink);
    left.appendChild(breadcrumb);
    left.appendChild(image);
    right.appendChild(promo);
    right.appendChild(hover_zoom_target);
    right.appendChild(title);
    right.appendChild(codes);
    right.appendChild(swatch);
    right.appendChild(kspec);
    if( divInfo.getAttribute('data-hide-stock') != '1' )
        right.appendChild(stock);

    right.appendChild(price);
    right.appendChild(atc);
    
    if( divInfo.getAttribute('data-etd-show') == '1' )
        right.appendChild(etd);
    
    right.appendChild(icons);
    right.appendChild(ksp);
    right.appendChild(view);
    image.appendChild(buildImage(divInfo));

    // get feature and feature_ident
    feature = griditem.querySelector('input[name=k2c_additeminline_feature], input[name=feature]');
    feature_ident = griditem.querySelector('input[name=k2c_additeminline_feature_ident], input[name=feature_ident]');
    if(feature && feature_ident) {
        featureParam = '&feature=' + feature.value + '&feature_ident=' + feature_ident.value;
    }

    Dialogs.load();
    new Dialog({
        title: '',
        content: quickview,
        afterOpen: function(dlg) {
            var thumbs = document.querySelector('#quickview_thumbs');
            if(thumbs){
                thumbs.imagePickerCarousel = new nx.carousels.ImagePickerCarousel(thumbs);
            }
            new nx.addToCart.AddToCartForm('form.quickview_form');
            if(typeof etdp != 'undefined'){
                if (etdp.postcode() == readDatalayer(['global', 'postcode'])) {
                    etdp.getAllTargets('#dialog-content');
                    etdp.updateAllTargets();
                }else{
                    etdp.postcodeChanged(readDatalayer(['global', 'postcode']));
                }
            }

            fetch('/main-ajax-quickview?prodcode=' + prodcode + featureParam, {
                credentials: 'same-origin'})
            .then(response => {
                return response.text()
            })
            .then(html => {
                var tmp = document.createElement('div');
                function update(ident){
                    var content = tmp.querySelector(ident);
                    var placeholder = document.getElementById('dialog-content').querySelector(ident);
                    if(content && placeholder){
                        placeholder.innerHTML = content.innerHTML;
                    }
                }
                tmp.innerHTML = html;
                var gst = tmp.querySelector('.quickview_gstfree');
                var uomSpan = document.getElementById('dialog-content').querySelector('.price_uom');
                update('.quickview_codes');
                update('.quickview_stock');
                update('.quickview_icons');
                update('.quickview_ksp');
                update('.quickview_breadcrumb');
                update('.quickview_pricebreaks');
                if(gst && uomSpan) {
                    uomSpan.innerHTML = gst.innerHTML + uomSpan.innerHTML;
                }
                var pbw = tmp.querySelector('.pricebreaks_wrapper');
                var wrap = document.getElementById('dialog-content').querySelector('.quickview_right');
                if(pbw && wrap) {
                    wrap.classList.add('pricebreaks_wrapper');
                    wrap.setAttribute('data-pricebreaks',pbw.getAttribute('data-pricebreaks'));
                    nx.prices.watchPriceBreaks('#dialog-content');
                }
                // fire the omniture script for the Quickview
                eval(tmp.querySelector('script') && tmp.querySelector('script').innerHTML);  // eslint-disable-line no-eval
            });
        }
    }).open();
}

export {
    maybeQuickView,
    quickView
};
