/* globals $q, Variant, s */
import {variant} from '../products/variant';

function getGwPrice (type, target, prodcode) {
    // Replace tearget with price HTML
    if( type === 'html' ) {
        displayHtmlGwPrice(target, prodcode);
    }
    // Return JSON object containg price data
    else {
        getJsonGwPrice(prodcode);
    }
}

function getJsonGwPrice (prodcode) {
    fetch( '/main-ajax-gwprice?prodcode=' + prodcode, {
        credentials: 'same-origin',
        method: 'GET'
    })
    .then(response => response.json())
    .then(json => {
        // Update variant price
        variant.processGwPrice(json.data);
    });
}

function displayHtmlGwPrice (target, prodcode) {

    // Null out click event to prevent double click
    target.setAttribute('onclick',"");

    // Display spinner
    const spin_div = document.createElement('div');
    spin_div.style.marginLeft = '40px';
    spin_div.style.textAlign = 'center';
    const spinner = document.createElement('i');
    spinner.className = 'fa fa-spinner fa-spin';
    spin_div.insertBefore(spinner, null);
    target.innerHTML = '';
    target.insertBefore(spin_div, null);

    fetch( '/main-ajax-gwprice?prodcode=' + prodcode, {
        credentials: 'same-origin',
        method: 'GET'})
        .then(response => response.json())
        .then(data => {
            // This code will run for both failure and success. The ajax handler returns
            // the relevant HTML embedded in the JSON payload
            target.parentNode.innerHTML = data.html;
        });

    // Instrument click for price
    if (typeof s == "undefined") return false;
    s.linkTrackVars = "events";
    s.linkTrackEvents = "event30";
    s.events = "event30";
    s.tl(true,'o','Get Price Clicked');
    return true;
}

export {getGwPrice};
