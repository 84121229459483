export const fireDatalayerEvent = ev => {
    // if the event specifies a function and args, then fire it.
    if(ev.function && ev.args && !ev.fired) {
        const funcPath = ev.function.split('.');
        let func = window;
        while (funcPath.length && func) {
            func = func[funcPath.shift()];
        }
        if (typeof func === 'function') {
            func(ev.args);
            ev.fired = true;
        }
    }
};
