const reverseStr = str => [...str].reverse().join('');

export function getPasswordStrength(pwd) {
  var nScore=0, nLength=0, nAlphaUC=0, nAlphaLC=0, nNumber=0, nSymbol=0, nMidChar=0, nRequirements=0, nAlphasOnly=0, nNumbersOnly=0, nUnqChar=0, nRepChar=0, nRepInc=0, nConsecAlphaUC=0, nConsecAlphaLC=0, nConsecNumber=0, nConsecSymbol=0, nConsecCharType=0, nSeqAlpha=0, nSeqNumber=0, nSeqSymbol=0, nSeqChar=0, nReqChar=0, nMultConsecCharType=0;
  var nMultRepChar=1, nMultConsecSymbol=1;
  var nMultMidChar=2, nMultRequirements=2, nMultConsecAlphaUC=2, nMultConsecAlphaLC=2, nMultConsecNumber=2;
  var nReqCharType=3, nMultAlphaUC=3, nMultAlphaLC=3, nMultSeqAlpha=3, nMultSeqNumber=3, nMultSeqSymbol=3;
  var nMultLength=4, nMultNumber=4, nMultSymbol=6;
  var nd, arrPwd, arrPwdLen, a, b, bCharExists, sFwd, sRev, s, nMinReqChars;
  var nTmpAlphaUC="", nTmpAlphaLC="", nTmpNumber="", nTmpSymbol="";
  var sAlphaUC="0", sAlphaLC="0", sNumber="0", sSymbol="0", sMidChar="0", sRequirements="0", sAlphasOnly="0", sNumbersOnly="0", sRepChar="0", sConsecAlphaUC="0", sConsecAlphaLC="0", sConsecNumber="0", sSeqAlpha="0", sSeqNumber="0", sSeqSymbol="0";
  var sAlphas = "abcdefghijklmnopqrstuvwxyz";
  var sNumerics = "01234567890";
  var sSymbols = ")!@#$%^&*()";
  var sComplexity = "Password Strength";
  var sClass = '';
  var sStandards = "Below";
  var nMinPwdLen = 8;

  nScore = parseInt(pwd.length * nMultLength,10);
  nLength = pwd.length;
  arrPwd = pwd.replace(/\s+/g,"").split(/\s*/);
  arrPwdLen = arrPwd.length;
  
  /* Loop through password to check for Symbol, Numeric, Lowercase and Uppercase pattern matches */
  for (a=0; a < arrPwdLen; a++) {
    if (arrPwd[a].match(/[A-Z]/g)) {
      if (nTmpAlphaUC !== "") { if ((nTmpAlphaUC + 1) == a) { nConsecAlphaUC++; nConsecCharType++; } }
      nTmpAlphaUC = a;
      nAlphaUC++;
    }
    else if (arrPwd[a].match(/[a-z]/g)) { 
      if (nTmpAlphaLC !== "") { if ((nTmpAlphaLC + 1) == a) { nConsecAlphaLC++; nConsecCharType++; } }
      nTmpAlphaLC = a;
      nAlphaLC++;
    }
    else if (arrPwd[a].match(/[0-9]/g)) { 
      if (a > 0 && a < (arrPwdLen - 1)) { nMidChar++; }
      if (nTmpNumber !== "") { if ((nTmpNumber + 1) == a) { nConsecNumber++; nConsecCharType++; } }
      nTmpNumber = a;
      nNumber++;
    }
    else if (arrPwd[a].match(/[^a-zA-Z0-9_]/g)) { 
      if (a > 0 && a < (arrPwdLen - 1)) { nMidChar++; }
      if (nTmpSymbol !== "") { if ((nTmpSymbol + 1) == a) { nConsecSymbol++; nConsecCharType++; } }
      nTmpSymbol = a;
      nSymbol++;
    }
    /* Internal loop through password to check for repeat characters */
    bCharExists = false;
    for (b=0; b < arrPwdLen; b++) {
      if (arrPwd[a] == arrPwd[b] && a != b) { /* repeat character exists */
        bCharExists = true;
        /* 
        Calculate icrement deduction based on proximity to identical characters
        Deduction is incremented each time a new match is discovered
        Deduction amount is based on total password length divided by the
        difference of distance between currently selected match
        */
        nRepInc += Math.abs(arrPwdLen/(b-a));
      }
    }
    if (bCharExists) { 
      nRepChar++; 
      nUnqChar = arrPwdLen-nRepChar;
      nRepInc = (nUnqChar) ? Math.ceil(nRepInc/nUnqChar) : Math.ceil(nRepInc); 
    }
  }
  
  /* Check for sequential alpha string patterns (forward and reverse) */
  for (s=0; s < 23; s++) {
    sFwd = sAlphas.substring(s,parseInt(s+3,10));
    sRev = reverseStr(sFwd);
    if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqAlpha++; nSeqChar++;}
  }
  
  /* Check for sequential numeric string patterns (forward and reverse) */
  for (s=0; s < 8; s++) {
    sFwd = sNumerics.substring(s,parseInt(s+3,10));
    sRev = reverseStr(sFwd);
    if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqNumber++; nSeqChar++;}
  }
  
  /* Check for sequential symbol string patterns (forward and reverse) */
  for (s=0; s < 8; s++) {
    sFwd = sSymbols.substring(s,parseInt(s+3,10));
    sRev = reverseStr(sFwd);
    if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) { nSeqSymbol++; nSeqChar++;}
  }
  
/* Modify overall score value based on usage vs requirements */

  /* General point assignment */
  if (nAlphaUC > 0 && nAlphaUC < nLength) {  
    nScore = parseInt(nScore + ((nLength - nAlphaUC) * 2),10);
    sAlphaUC = "+ " + parseInt((nLength - nAlphaUC) * 2,10); 
  }
  if (nAlphaLC > 0 && nAlphaLC < nLength) {  
    nScore = parseInt(nScore + ((nLength - nAlphaLC) * 2),10); 
    sAlphaLC = "+ " + parseInt((nLength - nAlphaLC) * 2,10);
  }
  if (nNumber > 0 && nNumber < nLength) {  
    nScore = parseInt(nScore + (nNumber * nMultNumber),10);
    sNumber = "+ " + parseInt(nNumber * nMultNumber,10);
  }
  if (nSymbol > 0) {  
    nScore = parseInt(nScore + (nSymbol * nMultSymbol),10);
    sSymbol = "+ " + parseInt(nSymbol * nMultSymbol,10);
  }
  if (nMidChar > 0) {  
    nScore = parseInt(nScore + (nMidChar * nMultMidChar),10);
    sMidChar = "+ " + parseInt(nMidChar * nMultMidChar,10);
  }
  
  /* Point deductions for poor practices */
  if ((nAlphaLC > 0 || nAlphaUC > 0) && nSymbol === 0 && nNumber === 0) {  // Only Letters
    nScore = parseInt(nScore - nLength,10);
    nAlphasOnly = nLength;
    sAlphasOnly = "- " + nLength;
  }
  if (nAlphaLC === 0 && nAlphaUC === 0 && nSymbol === 0 && nNumber > 0) {  // Only Numbers
    nScore = parseInt(nScore - nLength,10); 
    nNumbersOnly = nLength;
    sNumbersOnly = "- " + nLength;
  }
  if (nRepChar > 0) {  // Same character exists more than once
    nScore = parseInt(nScore - nRepInc,10);
    sRepChar = "- " + nRepInc;
  }
  if (nConsecAlphaUC > 0) {  // Consecutive Uppercase Letters exist
    nScore = parseInt(nScore - (nConsecAlphaUC * nMultConsecAlphaUC),10); 
    sConsecAlphaUC = "- " + parseInt(nConsecAlphaUC * nMultConsecAlphaUC,10);
  }
  if (nConsecAlphaLC > 0) {  // Consecutive Lowercase Letters exist
    nScore = parseInt(nScore - (nConsecAlphaLC * nMultConsecAlphaLC),10); 
    sConsecAlphaLC = "- " + parseInt(nConsecAlphaLC * nMultConsecAlphaLC,10);
  }
  if (nConsecNumber > 0) {  // Consecutive Numbers exist
    nScore = parseInt(nScore - (nConsecNumber * nMultConsecNumber),10);  
    sConsecNumber = "- " + parseInt(nConsecNumber * nMultConsecNumber,10);
  }
  if (nSeqAlpha > 0) {  // Sequential alpha strings exist (3 characters or more)
    nScore = parseInt(nScore - (nSeqAlpha * nMultSeqAlpha),10); 
    sSeqAlpha = "- " + parseInt(nSeqAlpha * nMultSeqAlpha,10);
  }
  if (nSeqNumber > 0) {  // Sequential numeric strings exist (3 characters or more)
    nScore = parseInt(nScore - (nSeqNumber * nMultSeqNumber),10); 
    sSeqNumber = "- " + parseInt(nSeqNumber * nMultSeqNumber,10);
  }
  if (nSeqSymbol > 0) {  // Sequential symbol strings exist (3 characters or more)
    nScore = parseInt(nScore - (nSeqSymbol * nMultSeqSymbol),10); 
    sSeqSymbol = "- " + parseInt(nSeqSymbol * nMultSeqSymbol,10);
  }
  nRequirements = nReqChar;
  if (pwd.length >= nMinPwdLen) { nMinReqChars = 3; } else { nMinReqChars = 4; }
  if (nRequirements > nMinReqChars) {  // One or more required characters exist
    nScore = parseInt(nScore + (nRequirements * 2),10); 
    sRequirements = "+ " + parseInt(nRequirements * 2,10);
  }

  /* Determine complexity based on overall score */
  if (nScore > 100) { nScore = 100; } else if (nScore < 0) { nScore = 0; }
  if (nScore >= 0 && nScore < 20) { sComplexity = "Very Weak"; sClass = 'password-vweak';}
  else if (nScore >= 20 && nScore < 40) { sComplexity = "Weak"; sClass = 'password-weak';}
  else if (nScore >= 40 && nScore < 60) { sComplexity = "Good"; sClass = 'password-good';}
  else if (nScore >= 60 && nScore < 80) { sComplexity = "Strong"; sClass = 'password-strong';}
  else if (nScore >= 80 && nScore <= 100) { sComplexity = "Very Strong"; sClass = 'password-vstrong';}
  
  return {
    sComplexity,
    sClass,
    nScore
  };
}

export function chkPass(pwd) {
  var ret;  
  var oScorebar = document.getElementById("password_scorebar");
  var oComplexity = document.getElementById("password_complexity");

  if (pwd) {
    ret = getPasswordStrength(pwd);

  /* Display updated score criteria to client */
  // oScorebar.style.backgroundPosition = "-" + parseInt(nScore * 4,10) + "px";    
  //oScore.innerHTML = nScore + "%";
  oComplexity.innerHTML = ret.sComplexity;
  oComplexity.className = ret.sClass;

  } else {
    /* Display default score criteria to client */
    initPwdChk();
    oComplexity.innerHTML = "Password Strength";
    document.getElementById("password_complexity").className = "";
  }
}

function initPwdChk(restart) {
    document.getElementById("password_complexity").innerHTML = "Password Strength";
    document.getElementById("password_complexity").className = "";
}

