import { addClassName, getElementByID, removeClassName } from '../ute/utils';

const refine_open = () => {
  const mobile_search_container = getElementByID('mobile_search_container');
  addClassName(mobile_search_container, 'show_refine');
  removeClassName(mobile_search_container, 'close_refine');
  addClassName(getElementByID('body'), 'refine_body');
  addClassName(document.body, 'active_refine_menu');
  /*Refine menu height adjustment*/
  var height_viewport = document.documentElement.clientHeight;
  var height_refine = getElementByID('refine_head').clientHeight;
  var refinelist_height = height_viewport - height_refine;
  getElementByID('refine_main_menu').style.height = refinelist_height + 'px';
}

const refine_close = () => {
  const mobile_search_container = getElementByID('mobile_search_container');
  addClassName(mobile_search_container, 'close_refine');
  removeClassName(mobile_search_container, 'show_refine');
  removeClassName(document.body, 'active_refine_menu');
  removeClassName(getElementByID('body'), 'refine_body');
}

const filterRefinements = elem => {
  var container = elem.closest('.refine-dimension-box');
  var term = elem.value.toLowerCase();
  var opts = container.querySelectorAll('.refine-dimension-value[data-name]');
  opts.forEach(function (opt) {
    var optName = opt.getAttribute('data-name').toLowerCase();
    if (optName.indexOf(term) > -1) {
      removeClassName(opt, 'filtered');
    } else {
      addClassName(opt, 'filtered');
    }
  });
}

const searchFilterRefinements = elem => {
  var container = elem.closest('.sidebar-accordion-box');
  var term = elem.value.toLowerCase();
  var opts = container.querySelectorAll('.sidebar-accordion__value[data-name]');
  opts.forEach(function (opt) {
    var optName = opt.getAttribute('data-name').toLowerCase();
    if (optName.indexOf(term) > -1) {
      removeClassName(opt, 'filtered');
    } else {
      addClassName(opt, 'filtered');
    }
  });
}
export { refine_open, refine_close, filterRefinements, searchFilterRefinements };
