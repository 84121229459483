import {
    Swipable, showSwipeIcon
} from './Swipable.js';
import {
    BasicCarousel
} from './BasicCarousel.js';

//PDP image picker carousels
var ImagePickerCarousel = function ImagePickerCarousel(wrapper) {
    this.wrapper = wrapper;
    this.middle = wrapper.querySelector('.middle');
    this.inner = wrapper.querySelector('.inner');
    var maxWidth = wrapper.clientWidth;
    this.imagesPerPane = Math.floor(maxWidth / 95);
    this.PANE_WIDTH = this.imagesPerPane * 95;
    this.middle.style.width = this.PANE_WIDTH + 'px';
    this.curPos = 0;
    this.numImages = this.inner.querySelectorAll('img').length;
    this.numPanes = Math.ceil(this.numImages / this.imagesPerPane);
    this.localStorageName = 'swipedOnPDP';
    this.adobeDescription = 'pdp image carousel';
    this.swipeEvent = 'event92';
    this.clickEvent = 'event93';
    this.navLeftClass = 'carousel-control prev';
    this.navRightClass = 'carousel-control next';
    this.setupNav();
    new Swipable(
        this.slideNext.bind(this),
        this.slidePrev.bind(this),
        this.resetScroll.bind(this),
        this.animateScroll.bind(this),
        this.inner,
        30
    );
    if (!localStorage.getItem(this.localStorageName)) {
        showSwipeIcon(wrapper, 'swipe_icon_pdp');
    }
    return this;
}
ImagePickerCarousel.prototype = new BasicCarousel();
ImagePickerCarousel.prototype.constructor = ImagePickerCarousel;
ImagePickerCarousel.prototype.resetCarousel = function resetCarousel() {
    this.curPos = 0;
    this.slideTo(0);
    this.numImages = this.inner.querySelectorAll('img').length;
    this.numHidden = this.inner.querySelectorAll('img.display_none').length;
    this.numPanes = Math.ceil((this.numImages - this.numHidden) / this.imagesPerPane);
    this.updateNav(this.curPos);
}

export {ImagePickerCarousel};
