function previouslyOrdered() {
  const uri = `/main-ajax-previously?userid=${nxDatalayer.global.userid}`
  fetch(uri)
  .then(response => response.json())
  .then(({data}) => {
    if (typeof data != 'object') { return; }
    for (let prodcode in data) {
      const div = document.querySelector(`.promo_container[data-itemkey="${prodcode}"]`);
      if (div && (div.innerHTML == '' || div.classList.contains('ps_ts_container'))) {
        div.innerHTML = '<div class="promo_strip ps_exact">Bought before</div>'; 
        window.nx.ute.addClassName(div, 'ps_exact_container')
      }
    }
  });
}

export {previouslyOrdered};
